import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  mainBlue,
  mainWhite,
  mainBlack,
  mainGreen,
  mainBrown,
  screen,
} from "../components/variables";
import gsap from "gsap";
import ContactForm from "../components/contact-form";
import styled from "styled-components";

const Wrapper = styled.div`
  background: ${mainBrown};
  color: ${mainBlack};

  .inner-wrapper--services {
    visibility: hidden;
  }

  .intro {
    padding: 180px 0 0 0;
    max-width: 935px;
    @media ${screen.xsmall} {
      padding: 220px 0 0 0;
    }
    @media ${screen.large} {
      padding: 235px 0 0 0;
    }

    @media ${screen.xlarge} {
      padding: 265px 0 0 0;
    }

    .heading {
      font-weight: 700;
      font-size: 1.9rem;
      @media ${screen.xsmall} {
        font-size: 2.2rem;
      }
      @media ${screen.medium} {
        font-size: 2.4rem;
      }
    }

    .description {
      margin: 33px 0 0 0;

      p {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.35;
        margin: 0 0 20px 0;
        @media ${screen.xsmall} {
          font-size: 1.6rem;
        }
        @media ${screen.medium} {
          font-size: 1.88rem;
        }
      }
    }
  }

  .services {
    margin: 90px 0 0 0;
    padding: 0 0 0 0;
    @media ${screen.xsmall} {
      padding: 0 0 145px 0;
    }

    .row {
      display: flex;
      flex-direction: column;
      margin: 0 0 46px 0;
      @media ${screen.xsmall} {
        flex-direction: row;
      }

      .col {
        &--left {
          @media ${screen.xsmall} {
            min-width: 290px;
            width: 22%;
          }

          .heading {
            font-weight: 700;
            line-height: 1.35;
            font-size: 1.35rem;
            @media ${screen.xsmall} {
              font-size: 1.7rem;
            }
          }
        }

        &--right {
          @media ${screen.xsmall} {
            padding: 0 0 0 70px;
          }

          @media ${screen.small} {
            padding: 0 0 0 90px;
          }

          .service-list {
            margin: 30px 0 60px 0;
            @media ${screen.xsmall} {
              margin: 0;
            }

            &__each {
              font-size: 1.1rem;
              margin: 0 0 26px 0;
              @media ${screen.xsmall} {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
`;

const ServicesPage = ({ data }) => {
  const {
    main_heading,
    main_description,
    service_heading_1,
    service_list_1,
    service_heading_2,
    service_list_2,
    form_heading,
    title_tag,
    meta_description,
  } = data.content.data;

  useEffect(() => {
    // animate intro
    const intro = gsap.timeline();

    intro
      .from(".inner-wrapper--services", { autoAlpha: 0 })
      .from(".intro .heading", { opacity: 0, y: 5 })
      .from(".intro .description", { opacity: 0, y: 5 })
      .from(".services .row--one", { opacity: 0, y: 5 })
      .from(".services .row--two", { opacity: 0, y: 5 });
  }, []);

  return (
    <Layout isServicesPage={true}>
      <SEO
        title={title_tag}
        description={meta_description}
        webPageInfo={{
          name: title_tag,
          url: "https://www.fmondaysmarketing.com/services/",
          description: meta_description,
        }}
      />
      <Wrapper>
        <div className="inner-wrapper inner-wrapper--services">
          <header className="intro">
            <h1 className="heading">{main_heading}</h1>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: main_description.html }}
            />
          </header>

          <section className="services">
            <div className="row row--one">
              <div className="col col--left">
                <h2 className="heading">{service_heading_1}</h2>
              </div>
              <div className="col col--right">
                <ul className="service-list">
                  {service_list_1.map((item) => (
                    <li className="service-list__each" key={item.item}>
                      {item.item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="row row--two">
              <div className="col col--left">
                <h2 className="heading">{service_heading_2}</h2>
              </div>
              <div className="col col--right">
                <ul className="service-list">
                  {service_list_2.map((item) => (
                    <li className="service-list__each" key={item.item}>
                      {item.item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
        </div>
        <ContactForm form_heading={form_heading} />
      </Wrapper>
    </Layout>
  );
};

export default ServicesPage;

export const dataQuery = graphql`
  {
    content: prismicServicesPage {
      data {
        main_heading
        main_description {
          html
        }
        service_heading_1
        service_list_1 {
          item
        }
        service_heading_2
        service_list_2 {
          item
        }
        form_heading
        title_tag
        meta_description
      }
    }
  }
`;
